// Medium devices (tablets, less than 1280px)
@media (max-width: 1285px) {
  section.o-nas-section-content {
    .team-section-content {
      .wave-bg {
        svg {
          top: -225px;
        }
      }
    }

    .gallery-section-content {
      .wave-bg {
        svg {
          top: -125px;
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  nav.section-header {
    .header-flexbox {
      .flexbox-nav-wrapper {
        .flexbox-nav-inner {
          &.inner-top {
            .flexbox-nav {
              li {
                font-size: 12px;
              }
            }
          }

          &.inner-bottom {
            .inner-wrapper {
              .flexbox-nav {
                width: 100%;
              }
              .flexbox-logo {
                width: 20%;
              }
            }
          }
        }
      }
    }
  }

  .heading {
    h2 {
      font-size: 42px;
    }
  }

  section.section-welcome {
    .section-wrapper {
      padding-bottom: 100px;

      .welcome-text {
        padding-top: 50px;

        h1 {
          font-size: 45px;
        }

        span {
          font-size: 16px;
        }
      }

      .welcome-img {
        img {
          transform: translate(0%, 12%);
        }
      }
    }
  }

  section.section-offer {
    .tab-content {
      .tab-wrapper {
        .image {
          width: 40%;
        }

        .text {
          width: 60%;
        }
      }
    }
  }

  section.section-icons {
    .icons-wrapper {
      .wrapper-content {
        width: 70%;
      }

      .wrapper-image {
        width: 30%;

        img {
          width: 100%;
        }
      }
    }
  }

  section.o-nas-section-content {
    .team-section-content {
      .team-content {
        .person {
          .person-text {
            padding: 0 0 0 70px;
          }
        }
      }
    }
  }

  section.section-team {
    .wave-bg {
      svg {
        top: -200px;
      }
    }

    .team-wrapper {
      .team-content {
        padding-top: 0;

        .person {
          width: 47%;
          margin-top: 40px;
        }
      }
    }
  }

  section.section-faq {
    .accordion {
      width: 80%;
      padding-right: 0;
    }

    .faq-image {
      display: none;
    }
  }

  section.o-nas-section-content {
    .team-section-content {
      .wave-bg {
        svg {
          top: -170px;
        }
      }
    }
  }

  section.cennik-section-content {
    .section-content {
      .cennik-nav {
        .nav-item {
          margin-top: 20px;

          .nav-pill {
            font-size: 13px;

            &.active {
              padding: 10px 15px;
            }
          }
        }
      }

      .tab-content {
        .tab-wrapper {
          .text {
            .table {
              width: 100%;

              .service {
                p:first-of-type {
                  width: 65%;
                }

                p:nth-of-type(2) {
                  width: 35%;
                }
              }
            }
          }
        }
      }
    }
  }

  .faq-section-content {
    .accordion-faq-content {
      padding: 50px 0 0 0;

      .accordion-nav {
        .nav-item {
          margin-top: 20px;

          .nav-pill {
            font-size: 13px;

            &.active {
              padding: 10px 15px;
            }
          }
        }
      }
    }
  }

  section.kontakt-section-content {
    .section-wrapper {
      .section-map {
        margin-left: 30px;
      }
    }
  }

  section.section-footer {
    padding-top: 50px;

    .footer-flexbox {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .img-section-absolute {
        top: -20%;
        right: -5%;
      }

      .flexbox-service {
        margin-top: 20px;
      }
    }

    .footer-credits-wrapper {
      text-align: center;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  nav.section-header {
    .header-flexbox {
      padding: 10px;
      border-bottom: 1px solid @color1;

      .flexbox-logo {
        &.sm-screen {
          display: block;
          width: auto;

          img {
            width: 70px;
          }
        }
      }

      .nav-button {
        display: flex;
      }

      .flexbox-nav-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        background: #fff;
        z-index: 555;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;

        .nav-button-close {
          display: flex;
          justify-content: flex-end;
        }

        .flexbox-nav-inner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 100%;
          padding: 50px;
          margin-right: 0;

          &.inner-top {
            display: none;
          }

          &.inner-bottom {
            background-color: #fff;
            height: 100%;

            .container {
              height: 100%;
            }

            .inner-wrapper {
              height: 100%;

              .flexbox-logo {
                display: none;
              }

              .flexbox-nav {
                height: 100%;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                li {
                  color: #212529;
                  padding: 20px 0;

                  a {
                    color: #212529;
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }

        &.active {
          opacity: 1;
          visibility: visible;
        }
      }

      .contact-mobile {
        display: flex;
        width: 70%;
        padding: 0 10px;

        .flexbox-nav {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 13px;
            letter-spacing: 1px;

            a {
              color: #212529;
              margin: 0 20px;

              img {
                width: 20px;
              }
            }
          }
        }
      }
    }
  }

  .heading {
    .img-absolute {
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }

  section.section-welcome {
    .section-wrapper {
      .welcome-text {
        h1 {
          font-size: 34px;
        }

        span {
          font-size: 15px;
        }
      }

      .welcome-img {
        img {
          transform: translate(0%, 20%);
        }
      }
    }
  }

  section.section-about {
    .about-wrapper {
      flex-direction: column;

      .img-section-absolute {
        display: none;
      }

      .wrapper-text {
        text-align: center;
        width: 100%;
        margin-top: 40px;

        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
        }
      }

      .wrapper-image {
        width: 100%;
      }
    }
  }

  section.section-icons {
    .icons-wrapper {
      flex-direction: column;

      .wrapper-content {
        width: 100%;
        text-align: center;

        .content-icons {
          .icon {
            img {
              width: 50px;
            }
          }
        }
      }

      .wrapper-image {
        display: none;
      }
    }
  }

  section.section-offer,
  section.offer-section-content {
    .offer-nav {
      flex-direction: column;
      align-items: center;

      .nav-item {
        &:not(:first-of-type) {
          margin-top: 20px;
        }

        .nav-pill {
          font-size: 15px;

          &.active {
            padding: 15px 20px;
          }
        }
      }
    }

    .tab-content {
      .tab-wrapper {
        .image {
          display: none;
        }

        .text {
          width: 100%;
          padding-left: 0;
          text-align: center;

          ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
          }
        }
      }
    }
  }

  section.section-faq {
    .btn-default {
      align-self: center;
    }
  }

  .faq-section-content {
    .accordion-faq-content {
      .accordion-nav {
        flex-direction: column;
        align-items: center;

        .nav-item {
          text-align: center;

          &:not(:first-of-type) {
            margin-top: 40px;
          }

          .nav-pill {
            &.active {
              padding: 15px 20px;
            }
          }
        }
      }

      .accordion-item {
        .item-description {
          text-align: center;
          padding: 40px 0;
        }

        button {
          span {
            text-align: center;
          }

          &.collapsed {
            span {
              text-align: left;
              padding: 0 20px 0 10px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  section.cennik-section-content {
    padding: 70px 0;

    .section-content {
      .cennik-nav {
        flex-direction: column;
        align-items: center;

        .nav-item {
          &:not(:first-of-type) {
            margin-top: 40px;
          }

          .nav-pill {
            font-size: 15px;

            &.active {
              padding: 15px 20px;
            }
          }
        }
      }
    }
  }

  section.o-nas-section-content,
  .static-page-section-content {
    .team-section-content {
      .wave-bg {
        svg {
          top: -120px;
        }
      }

      .team-content {
        .person,
        .person:nth-of-type(even) {
          flex-direction: column;
          padding: 30px 0;

          .person-image {
            width: 70%;
            justify-content: center;
          }

          .person-text {
            width: 70%;
            padding: 30px 0 0 0;
            text-align: center;

            ul {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 20px;
            }
          }
        }
      }
    }

    .section-wrapper {
      .backlink {
        margin-bottom: 25px;
        align-self: center;
        transform: translatex(0);
        padding-left: 0;

        span {
          display: none;
        }

        &:hover {
          transform: translatex(0);
        }
      }

      .wrapper-service {
        .service-content {
          .service {
            width: 50%;
          }
        }
      }
    }

    .icons-section-content {
      .icons-wrapper {
        flex-direction: column;
        align-items: center;

        .icon {
          width: 50%;
        }
      }
    }

    .gallery-section-content {
      .section-wrapper {
        padding-top: 70px;

        .gallery-wrapper {
          .flexbox-service-wrapper {
            width: 47%;
          }
        }
      }
    }

    .cta-section-content {
      .section-wrapper {
        .text {
          width: 100%;

          &:first-of-type {
            h2 {
              font-size: 38px;
            }
          }

          &:nth-of-type(2) {
            h3 {
              font-size: 28px;
            }
          }
        }
      }
    }
  }

  section.kontakt-section-content {
    .section-wrapper {
      flex-direction: column;
      align-items: center;

      .section-text {
        width: 100%;
        text-align: center;

        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
        }
      }

      .section-map {
        width: 100%;
        margin-left: 0;
        margin-top: 50px;

        iframe {
          height: 250px;
        }
      }
    }
  }

  section.section-footer {
    padding-top: 50px;

    .footer-flexbox {
      flex-direction: column;
      align-items: center;

      .img-section-absolute {
        top: -20%;
        width: 30%;
      }

      .flexbox-service {
        text-align: center;
        margin-top: 20px;
      }
    }

    .footer-credits,
    .alert {
      p {
        text-align: center;
      }
    }
  }
}

// Extra small devices (portrait phones, less than 681px)
@media (max-width: 680.98px) {
  section.section-team {
    .wave-bg {
      svg {
        top: -170px;
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .heading {
    margin-bottom: 25px;

    h2 {
      font-size: 32px;
    }

    .img-absolute {
      bottom: 0;
      width: 70%;
    }
  }

  section.section-welcome {
    padding-top: 60px;

    .section-wrapper {
      justify-content: center;

      .welcome-text {
        width: 80%;
        padding-top: 0;

        h1 {
          font-size: 45px;
        }
      }

      .welcome-img {
        display: none;
      }
    }
  }

  section.section-icons {
    padding-top: 70px;

    .icons-wrapper {
      flex-direction: column;

      .wrapper-content {
        padding-right: 0;

        .content-icons {
          flex-direction: column;
          align-items: center;

          .icon {
            width: 55%;

            p {
              font-size: 17px;
            }
          }
        }
      }
    }
  }

  section.section-offer,
  section.offer-section-content {
    padding: 70px 0 100px 0;

    .tab-content {
      .tab-wrapper {
        .text {
          ul {
            align-items: flex-start;
            text-align: left;
          }
        }
      }
    }
  }

  section.section-team {
    margin-top: 0;

    .team-wrapper {
      .team-content {
        flex-direction: column;

        .person {
          width: 65%;

          img {
            opacity: 1;
          }

          .text {
            background-color: rgba(#fff, 0.9);
          }
        }
      }
    }
  }

  section.o-nas-section-content,
  .static-page-section-content {
    padding: 70px 0;

    .section-wrapper {
      .wrapper-text {
        &.text-left {
          p {
            text-align: center;
          }
        }
      }

      .wrapper-service {
        .service-content {
          justify-content: center;

          .service {
            width: 80%;
          }
        }
      }
    }

    .gallery-section-content {
      .wave-bg {
        svg {
          top: -85px;
        }
      }

      .section-wrapper {
        padding-top: 0;

        .gallery-wrapper {
          padding-top: 50px;
          justify-content: center;

          .flexbox-service-wrapper {
            width: 90%;
          }
        }
      }
    }

    .team-section-content {
      margin-top: 150px;

      .wave-bg {
        svg {
          top: -90px;
        }
      }
    }

    .cta-section-content {
      .section-wrapper {
        .text {
          flex-direction: column;

          &:first-of-type {
            h2 {
              text-align: center;
              font-size: 28px;
              line-height: 38px;
            }
          }

          &:nth-of-type(2) {
            text-align: center;

            h3 {
              font-size: 22px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  section.section-footer {
    .footer-flexbox {
      .img-section-absolute {
        right: 3%;
        width: 25%;
      }
    }
  }
}

// Extra small devices (portrait phones, less than 530px)
@media (max-width: 530px) {
  section.section-team {
    .wave-bg {
      svg {
        top: -120px;
      }
    }

    .team-wrapper {
      .team-content {
        .person {
          width: 70%;
        }
      }
    }
  }

  section.o-nas-section-content,
  .static-page-section-content {
    .team-section-content {
      .team-content {
        .person,
        .person:nth-of-type(even) {
          justify-content: center;

          .person-image {
            height: 350px;
            width: auto;
          }
        }
      }
    }
  }

  section.section-faq {
    padding-bottom: 70px;

    .accordion {
      width: 100%;

      .accordion-item {
        .item-description {
          text-align: center;
          padding: 20px 0 30px 0;
        }

        button {
          span {
            text-align: center;
          }

          &.collapsed {
            span {
              text-align: left;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  section.section-footer {
    .footer-flexbox {
      .img-section-absolute {
        top: -12%;
      }
    }
  }
}

// Extra small devices (portrait phones, less than 400px)
@media (max-width: 400px) {
  section.section-welcome {
    .wave-bg {
      svg {
        bottom: 20px;
      }
    }
  }

  section.section-icons {
    .icons-wrapper {
      .wrapper-content {
        .content-icons {
          .icon {
            width: 80%;
          }
        }
      }
    }
  }

  section.o-nas-section-content {
    padding: 50px 0;

    .team-section-content {
      margin-top: 100px;

      .wave-bg {
        svg {
          top: -70px;
        }
      }

      .team-content {
        .person,
        .person:nth-of-type(even) {
          .person-text {
            width: 85%;
          }
        }
      }
    }

    .icons-section-content {
      padding-top: 40px;

      .icons-wrapper {
        .icon {
          width: 80%;
        }
      }
    }
  }
}
