@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v22/S6u9w4BMUTPHh7USSwaPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v22/S6uyw4BMUTPHjxAwWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v22/S6u9w4BMUTPHh6UVSwaPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v22/S6u9w4BMUTPHh50XSwaPHA.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
body .pt-50 {
  padding-top: 50px;
}
body .pt-120 {
  padding-top: 120px;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.btn {
  border: none;
  background: none;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.btn.btn-default {
  background-color: rgba(19, 39, 103, 0.85);
  border-radius: 30px;
  padding: 13px 20px;
  color: #fff;
  transition: background-color 250ms;
}
.btn.btn-default:hover {
  background-color: #132767;
}
.heading {
  position: relative;
  padding: 5px 0;
  margin-bottom: 35px;
}
.heading h2 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.heading .img-absolute {
  position: absolute;
  bottom: -15px;
  left: 0;
  opacity: 0.8;
}
nav.section-header .header-flexbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-logo {
  width: 22%;
}
nav.section-header .header-flexbox .flexbox-logo img {
  width: 80px;
}
nav.section-header .header-flexbox .flexbox-logo.sm-screen {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper {
  width: 100%;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  right: 20px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 3px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-top {
  padding: 15px 0;
  background-color: #52c0af;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-top .flexbox-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-top .flexbox-nav li {
  display: flex;
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-top .flexbox-nav li img {
  width: 16px;
  margin-right: 5px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-top .flexbox-nav li a {
  color: #fff;
  transition: 250ms color;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-top .flexbox-nav li a:hover {
  color: #132767;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom {
  padding: 15px 0;
  background-color: #fff;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper {
  display: flex;
  justify-content: space-between;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav {
  display: flex;
  width: 65%;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav li a {
  color: #212529;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 900;
  transition: color 250ms;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav li a:hover {
  color: #52c0af;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav li.active {
  border: 1px solid #132767;
  border-radius: 20px;
  padding: 2px 12px;
  transition: all 250ms;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav li.active:hover {
  background: #52c0af;
  border: 1px solid transparent;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav li.active:hover a {
  color: #fff;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav .active {
  border-bottom: 1px solid #132767;
  padding-bottom: 8px;
  transition: all 250ms;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-logo {
  width: 40%;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-logo img {
  width: 80px;
}
nav.section-header .header-flexbox .contact-mobile {
  display: none;
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #52c0af;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
section.section-welcome {
  position: relative;
  padding-top: 70px;
  background-color: #52c0af;
}
section.section-welcome .wave-bg {
  position: relative;
  width: 100%;
  padding-top: 8%;
  background: #fff;
}
section.section-welcome .wave-bg svg {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
section.section-welcome .section-wrapper {
  display: flex;
}
section.section-welcome .section-wrapper .welcome-text {
  padding-top: 120px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
section.section-welcome .section-wrapper .welcome-text h1 {
  color: #fff;
  font-size: 60px;
  font-weight: 900;
  margin-bottom: 20px;
}
section.section-welcome .section-wrapper .welcome-text p:first-of-type {
  display: inline-block;
  font-size: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 700;
}
section.section-welcome .section-wrapper .welcome-text p:nth-of-type(2) {
  color: #fff;
  margin-top: 10px;
  letter-spacing: 1px;
  line-height: 26px;
}
section.section-welcome .section-wrapper .welcome-text .btn {
  margin-top: 50px;
}
section.section-welcome .section-wrapper .welcome-img {
  width: 40%;
}
section.section-welcome .section-wrapper .welcome-img img {
  width: 100%;
}
section.section-about {
  position: relative;
}
section.section-about .about-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
section.section-about .about-wrapper .img-section-absolute {
  position: fixed;
  top: 30%;
  z-index: 11;
  left: -4%;
  opacity: 0.4;
  width: 12%;
}
section.section-about .about-wrapper .wrapper-image {
  width: 50%;
}
section.section-about .about-wrapper .wrapper-image img {
  width: 100%;
  border-radius: 10px;
}
section.section-about .about-wrapper .wrapper-text {
  width: 45%;
}
section.section-about .about-wrapper .wrapper-text p {
  line-height: 30px;
}
section.section-about .about-wrapper .wrapper-text p:not(:first-of-type) {
  margin-top: 10px;
}
section.section-about .about-wrapper .wrapper-text h3 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  padding: 0;
  margin: 10px 0;
}
section.section-about .about-wrapper .wrapper-text ul {
  margin-top: 10px;
  list-style-type: none;
}
section.section-about .about-wrapper .wrapper-text ul li {
  position: relative;
  padding: 5px 0 5px 20px;
  display: flex;
}
section.section-about .about-wrapper .wrapper-text ul li:before {
  position: absolute;
  left: 0;
  top: 6px;
  content: ">";
  font-size: 20px;
  line-height: 25px;
  color: #52c0af;
  font-weight: 900;
}
section.section-about .about-wrapper .wrapper-text .btn {
  margin-top: 30px;
}
section.section-icons {
  padding-top: 120px;
}
section.section-icons .icons-wrapper {
  display: flex;
  align-items: center;
}
section.section-icons .icons-wrapper .wrapper-image {
  width: 50%;
}
section.section-icons .icons-wrapper .wrapper-image img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
section.section-icons .icons-wrapper .wrapper-content {
  width: 50%;
  padding-right: 40px;
}
section.section-icons .icons-wrapper .wrapper-content .content-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
section.section-icons .icons-wrapper .wrapper-content .content-icons .icon {
  width: 47%;
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  transition: all 350ms;
}
section.section-icons .icons-wrapper .wrapper-content .content-icons .icon img {
  width: 40px;
  margin-right: 16px;
}
section.section-icons .icons-wrapper .wrapper-content .content-icons .icon p {
  text-align: left;
  font-weight: 700;
  line-height: 30px;
}
section.section-icons .icons-wrapper .wrapper-content .content-icons .icon:hover {
  transform: translateY(-5px);
}
section.section-icons .icons-wrapper .wrapper-content .content-icons .icon:hover p {
  color: #132767;
}
section.section-icons .icons-wrapper .wrapper-content .btn {
  margin-top: 50px;
}
section.section-offer,
.offer-section-content {
  padding: 100px 0 150px 0;
}
section.section-offer .heading h2,
.offer-section-content .heading h2 {
  text-align: center;
}
section.section-offer .offer-nav,
.offer-section-content .offer-nav {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
section.section-offer .offer-nav .nav-item,
.offer-section-content .offer-nav .nav-item {
  text-align: center;
}
section.section-offer .offer-nav .nav-item .nav-pill,
.offer-section-content .offer-nav .nav-item .nav-pill {
  font-size: 14px;
  letter-spacing: 1px;
  padding: 15px 20px;
  text-transform: uppercase;
  color: #212529;
  font-weight: 700;
}
section.section-offer .offer-nav .nav-item .nav-pill.active,
.offer-section-content .offer-nav .nav-item .nav-pill.active {
  background-color: rgba(19, 39, 103, 0.85);
  border-radius: 30px;
  padding: 15px 20px;
  color: #fff;
  transition: background-color 250ms;
}
section.section-offer .offer-nav .nav-item .nav-pill.active:hover,
.offer-section-content .offer-nav .nav-item .nav-pill.active:hover {
  background-color: #132767;
}
section.section-offer .tab-content,
.offer-section-content .tab-content {
  padding-top: 80px;
}
section.section-offer .tab-content .tab-wrapper,
.offer-section-content .tab-content .tab-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
}
section.section-offer .tab-content .tab-wrapper .text,
.offer-section-content .tab-content .tab-wrapper .text {
  padding-left: 50px;
  width: 50%;
}
section.section-offer .tab-content .tab-wrapper .text p,
.offer-section-content .tab-content .tab-wrapper .text p {
  line-height: 30px;
}
section.section-offer .tab-content .tab-wrapper .text h3,
.offer-section-content .tab-content .tab-wrapper .text h3 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}
section.section-offer .tab-content .tab-wrapper .text h4,
.offer-section-content .tab-content .tab-wrapper .text h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0;
}
section.section-offer .tab-content .tab-wrapper .text ul,
.offer-section-content .tab-content .tab-wrapper .text ul {
  list-style-type: none;
}
section.section-offer .tab-content .tab-wrapper .text ul li,
.offer-section-content .tab-content .tab-wrapper .text ul li {
  position: relative;
  padding: 5px 0px 5px 20px;
  display: flex;
}
section.section-offer .tab-content .tab-wrapper .text ul li:before,
.offer-section-content .tab-content .tab-wrapper .text ul li:before {
  position: absolute;
  left: 0;
  top: 8px;
  content: ">";
  font-size: 18px;
  line-height: 20px;
  color: #52c0af;
  font-weight: 900;
}
section.section-offer .tab-content .tab-wrapper .image,
.offer-section-content .tab-content .tab-wrapper .image {
  width: 50%;
}
section.section-offer .tab-content .tab-wrapper .image img,
.offer-section-content .tab-content .tab-wrapper .image img {
  width: 100%;
  border-radius: 10px;
}
section.section-offer .tab-content .tab-wrapper .btn,
.offer-section-content .tab-content .tab-wrapper .btn {
  margin-top: 30px;
}
.offer-section-content .heading .img-absolute {
  left: 50%;
  top: 50%;
  transform: translate(-50%, 80%);
}
.offer-section-content .offer-content {
  padding-top: 80px;
}
.offer-section-content .offer-text {
  padding-top: 20px;
}
.offer-section-content .offer-text p {
  line-height: 32px;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
section.section-team {
  position: relative;
  z-index: 111;
  padding: 50px 0;
  margin-top: 60px;
  background-color: #f8f8f8;
}
section.section-team .wave-bg {
  position: relative;
  width: 100%;
}
section.section-team .wave-bg svg {
  display: block;
  position: absolute;
  top: -300px;
  left: 0;
}
section.section-team .team-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.section-team .team-wrapper .team-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  width: 100%;
}
section.section-team .team-wrapper .team-content .person {
  width: 24%;
  height: 350px;
  position: relative;
  margin: 20px;
}
section.section-team .team-wrapper .team-content .person img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100%;
  transition: opacity 350ms ease-in-out;
  opacity: 0.8;
  border-radius: 10px;
}
section.section-team .team-wrapper .team-content .person:hover img {
  opacity: 1;
}
section.section-team .team-wrapper .team-content .person:hover .text {
  background-color: #fff;
}
section.section-team .team-wrapper .team-content .person .text {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 350ms ease-in-out;
}
section.section-team .team-wrapper .team-content .person .text .name {
  font-size: 20px;
  font-weight: 700;
}
section.section-team .team-wrapper .team-content .person .text .position {
  color: #132767;
  font-weight: 700;
  letter-spacing: 1px;
}
section.section-team .team-wrapper .team-content .person .text .description {
  margin-top: 10px;
  font-size: 14px;
  line-height: 26px;
}
section.section-partners {
  position: relative;
  z-index: 111;
  padding: 50px 0 100px 0;
  background-color: #f8f8f8;
}
section.section-partners .partners-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.section-partners .owl-carousel-cooperation {
  margin-top: 50px;
}
section.section-partners .owl-carousel-cooperation .item {
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-partners .owl-carousel-cooperation .item img {
  width: 60%;
  opacity: 0.6;
  transition: opacity 350ms;
}
section.section-partners .owl-carousel-cooperation .item img:hover {
  opacity: 1;
}
section.section-partners .owl-carousel-cooperation .owl-nav {
  display: none;
}
section.section-partners .owl-carousel-cooperation .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}
section.section-partners .owl-carousel-cooperation .owl-dots .owl-dot.active span {
  background: #132767;
  width: 14px;
  height: 14px;
}
section.section-faq,
.faq-section-content {
  padding-bottom: 120px;
  width: 100%;
}
section.section-faq .wave-bg,
.faq-section-content .wave-bg {
  position: relative;
  z-index: -1;
  width: 100%;
  padding-top: 10%;
  background: #fff;
}
section.section-faq .wave-bg svg,
.faq-section-content .wave-bg svg {
  display: block;
  position: absolute;
  bottom: -15%;
  left: 0;
}
section.section-faq .faq-wrapper,
.faq-section-content .faq-wrapper {
  display: flex;
  flex-direction: column;
}
section.section-faq .faq-content,
.faq-section-content .faq-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
section.section-faq .accordion,
.faq-section-content .accordion {
  width: 55%;
}
section.section-faq .accordion,
.faq-section-content .accordion,
section.section-faq .accordion-faq-content,
.faq-section-content .accordion-faq-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 50px;
}
section.section-faq .accordion .accordion-nav,
.faq-section-content .accordion .accordion-nav,
section.section-faq .accordion-faq-content .accordion-nav,
.faq-section-content .accordion-faq-content .accordion-nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
section.section-faq .accordion .accordion-nav .nav-item,
.faq-section-content .accordion .accordion-nav .nav-item,
section.section-faq .accordion-faq-content .accordion-nav .nav-item,
.faq-section-content .accordion-faq-content .accordion-nav .nav-item {
  text-align: center;
}
section.section-faq .accordion .accordion-nav .nav-item .nav-pill,
.faq-section-content .accordion .accordion-nav .nav-item .nav-pill,
section.section-faq .accordion-faq-content .accordion-nav .nav-item .nav-pill,
.faq-section-content .accordion-faq-content .accordion-nav .nav-item .nav-pill {
  font-size: 14px;
  letter-spacing: 1px;
  padding: 15px 20px;
  text-transform: uppercase;
  color: #212529;
  font-weight: 700;
}
section.section-faq .accordion .accordion-nav .nav-item .nav-pill.active,
.faq-section-content .accordion .accordion-nav .nav-item .nav-pill.active,
section.section-faq .accordion-faq-content .accordion-nav .nav-item .nav-pill.active,
.faq-section-content .accordion-faq-content .accordion-nav .nav-item .nav-pill.active {
  background-color: rgba(19, 39, 103, 0.85);
  border-radius: 30px;
  padding: 15px 20px;
  color: #fff;
  transition: background-color 250ms;
}
section.section-faq .accordion .accordion-nav .nav-item .nav-pill.active:hover,
.faq-section-content .accordion .accordion-nav .nav-item .nav-pill.active:hover,
section.section-faq .accordion-faq-content .accordion-nav .nav-item .nav-pill.active:hover,
.faq-section-content .accordion-faq-content .accordion-nav .nav-item .nav-pill.active:hover {
  background-color: #132767;
}
section.section-faq .accordion .accordion-item .item-description,
.faq-section-content .accordion .accordion-item .item-description,
section.section-faq .accordion-faq-content .accordion-item .item-description,
.faq-section-content .accordion-faq-content .accordion-item .item-description {
  padding: 20px 0 30px 15px;
}
section.section-faq .accordion .accordion-item .item-description p,
.faq-section-content .accordion .accordion-item .item-description p,
section.section-faq .accordion-faq-content .accordion-item .item-description p,
.faq-section-content .accordion-faq-content .accordion-item .item-description p {
  font-size: 15px;
  line-height: 26px;
  margin-top: 10px;
}
section.section-faq .accordion .accordion-item .item-description .btn-faq,
.faq-section-content .accordion .accordion-item .item-description .btn-faq,
section.section-faq .accordion-faq-content .accordion-item .item-description .btn-faq,
.faq-section-content .accordion-faq-content .accordion-item .item-description .btn-faq {
  display: inline-block;
  color: #132767;
  font-size: 15px;
  margin-top: 15px;
  font-weight: 700;
  transition: all 250ms;
}
section.section-faq .accordion .accordion-item .item-description .btn-faq:hover,
.faq-section-content .accordion .accordion-item .item-description .btn-faq:hover,
section.section-faq .accordion-faq-content .accordion-item .item-description .btn-faq:hover,
.faq-section-content .accordion-faq-content .accordion-item .item-description .btn-faq:hover {
  color: #52c0af;
}
section.section-faq .accordion .accordion-item .collapsed,
.faq-section-content .accordion .accordion-item .collapsed,
section.section-faq .accordion-faq-content .accordion-item .collapsed,
.faq-section-content .accordion-faq-content .accordion-item .collapsed {
  background-color: #f8f8f8;
  margin-bottom: 10px;
  color: #020202;
  display: block;
}
section.section-faq .accordion .accordion-item .collapsed span:after,
.faq-section-content .accordion .accordion-item .collapsed span:after,
section.section-faq .accordion-faq-content .accordion-item .collapsed span:after,
.faq-section-content .accordion-faq-content .accordion-item .collapsed span:after {
  position: absolute;
  right: 0;
  top: 0;
  content: ">";
  font-size: 20px;
  line-height: 20px;
  color: #132767;
  font-weight: 900;
}
section.section-faq .accordion .accordion-item button,
.faq-section-content .accordion .accordion-item button,
section.section-faq .accordion-faq-content .accordion-item button,
.faq-section-content .accordion-faq-content .accordion-item button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: rgba(19, 39, 103, 0.85);
  border-radius: 30px;
  color: #fff;
  width: 100%;
  padding: 15px 10px;
}
section.section-faq .accordion .accordion-item button span,
.faq-section-content .accordion .accordion-item button span,
section.section-faq .accordion-faq-content .accordion-item button span,
.faq-section-content .accordion-faq-content .accordion-item button span {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  padding: 0 20px;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
}
section.section-faq .accordion-faq-content,
.faq-section-content .accordion-faq-content {
  width: 100%;
  padding: 80px 0 0 0;
}
section.section-faq .accordion-faq-content .accordion-item button,
.faq-section-content .accordion-faq-content .accordion-item button {
  border-radius: 10px;
}
section.section-faq .faq-image,
.faq-section-content .faq-image {
  width: 45%;
}
section.section-faq .faq-image img,
.faq-section-content .faq-image img {
  width: 100%;
  border-radius: 10px;
}
section.section-faq .btn-default,
.faq-section-content .btn-default {
  margin-top: 30px;
  align-self: flex-start;
}
.faq-section-content {
  padding-bottom: 0;
}
section.o-nas-section-content {
  padding: 50px 0 100px 0;
}
section.o-nas-section-content .section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}
section.o-nas-section-content .section-wrapper .heading .img-absolute {
  left: 50%;
  top: 80%;
  transform: translate(-50%, 50%);
}
section.o-nas-section-content .section-wrapper .wrapper-text {
  padding-top: 20px;
}
section.o-nas-section-content .section-wrapper .wrapper-text p {
  line-height: 32px;
  font-size: 18px;
  text-align: center;
}
section.o-nas-section-content .section-wrapper .wrapper-text p:not(:first-of-type) {
  margin-top: 10px;
}
section.o-nas-section-content .team-section-content {
  position: relative;
  margin-top: 200px;
  background-color: #f8f8f8;
}
section.o-nas-section-content .team-section-content .wave-bg {
  position: relative;
  width: 100%;
}
section.o-nas-section-content .team-section-content .wave-bg svg {
  display: block;
  position: absolute;
  top: -290px;
  left: 0;
  z-index: -1;
}
section.o-nas-section-content .team-section-content .team-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section.o-nas-section-content .team-section-content .team-content .person {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
section.o-nas-section-content .team-section-content .team-content .person .person-image {
  width: 35%;
  height: 450px;
  display: flex;
  justify-content: flex-end;
}
section.o-nas-section-content .team-section-content .team-content .person .person-image img {
  height: 100%;
  border-radius: 10px;
}
section.o-nas-section-content .team-section-content .team-content .person .person-text {
  width: 60%;
  padding: 0 90px 0 70px;
}
section.o-nas-section-content .team-section-content .team-content .person .person-text .name {
  font-size: 30px;
  font-weight: 700;
}
section.o-nas-section-content .team-section-content .team-content .person .person-text .position {
  color: #132767;
  font-weight: 700;
  letter-spacing: 1px;
}
section.o-nas-section-content .team-section-content .team-content .person .person-text .description p {
  line-height: 28px;
  margin-top: 10px;
}
section.o-nas-section-content .team-section-content .team-content .person .person-text .description ul {
  margin-top: 10px;
  list-style-type: none;
}
section.o-nas-section-content .team-section-content .team-content .person .person-text .description ul li:first-of-type {
  margin-bottom: 10px;
  font-weight: 700;
  padding: 0;
  font-size: 20px;
}
section.o-nas-section-content .team-section-content .team-content .person .person-text .description ul li:first-of-type:before {
  display: none;
}
section.o-nas-section-content .team-section-content .team-content .person .person-text .description ul li {
  position: relative;
  padding: 5px 0 5px 20px;
  display: flex;
}
section.o-nas-section-content .team-section-content .team-content .person .person-text .description ul li:before {
  position: absolute;
  left: 0;
  top: 6px;
  content: ">";
  font-size: 20px;
  line-height: 25px;
  color: #52c0af;
  font-weight: 900;
}
section.o-nas-section-content .team-section-content .team-content .person:nth-of-type(even) {
  flex-direction: row-reverse;
}
section.o-nas-section-content .team-section-content .team-content .person:nth-of-type(even) .person-image {
  justify-content: flex-start;
}
section.o-nas-section-content .team-section-content .team-content .person:nth-of-type(even) .person-text {
  padding: 0 70px 0 90px;
}
section.o-nas-section-content .icons-section-content {
  padding-top: 70px;
  background-color: #f8f8f8;
}
section.o-nas-section-content .icons-section-content .heading .img-absolute {
  left: 50%;
  top: 80%;
  transform: translate(-50%, 50%);
}
section.o-nas-section-content .icons-section-content .heading h2 {
  text-align: center;
}
section.o-nas-section-content .icons-section-content .icons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
section.o-nas-section-content .icons-section-content .icons-wrapper .icon {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  transition: all 250ms;
}
section.o-nas-section-content .icons-section-content .icons-wrapper .icon img {
  width: 50px;
}
section.o-nas-section-content .icons-section-content .icons-wrapper .icon p {
  text-align: center;
  margin-top: 10px;
  transition: all 250ms;
  font-weight: 700;
}
section.o-nas-section-content .icons-section-content .icons-wrapper .icon:hover {
  transform: translateY(-5px);
}
section.o-nas-section-content .icons-section-content .icons-wrapper .icon:hover p {
  color: #132767;
}
section.o-nas-section-content .gallery-section-content {
  padding-top: 70px;
}
section.o-nas-section-content .gallery-section-content .wave-bg {
  position: relative;
  width: 100%;
}
section.o-nas-section-content .gallery-section-content .wave-bg svg {
  display: block;
  position: absolute;
  top: -220px;
  left: 0;
  z-index: -1;
}
section.o-nas-section-content .gallery-section-content .section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}
section.o-nas-section-content .gallery-section-content .section-wrapper .heading .img-absolute {
  left: 50%;
  top: 80%;
  transform: translate(-50%, 50%);
}
section.o-nas-section-content .gallery-section-content .section-wrapper .gallery-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 20px;
  width: 100%;
}
section.o-nas-section-content .gallery-section-content .section-wrapper .gallery-wrapper .flexbox-service {
  position: relative;
  overflow: hidden;
  margin: 7px;
  width: 32%;
  padding-top: 32%;
  border-radius: 20px;
}
section.o-nas-section-content .gallery-section-content .section-wrapper .gallery-wrapper .flexbox-service:hover img {
  transform: scale(1.03);
}
section.o-nas-section-content .gallery-section-content .section-wrapper .gallery-wrapper .flexbox-service img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease;
  transform: translateZ(0);
  object-fit: cover;
}
section.o-nas-section-content .gallery-section-content .section-wrapper .gallery-wrapper .service-hidden {
  width: 32%;
  visibility: hidden;
  opacity: 0;
}
section.o-nas-section-content .cta-section-content {
  padding-top: 50px;
}
section.o-nas-section-content .cta-section-content .section-wrapper .text {
  display: flex;
  width: 80%;
  padding: 20px 0;
}
section.o-nas-section-content .cta-section-content .section-wrapper .text:first-of-type {
  justify-content: flex-start;
}
section.o-nas-section-content .cta-section-content .section-wrapper .text:first-of-type h2 {
  font-size: 44px;
  line-height: 60px;
  font-weight: 700;
  color: #132767;
}
section.o-nas-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) {
  justify-content: flex-end;
  align-items: center;
}
section.o-nas-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) h3 {
  font-size: 32px;
  font-weight: 700;
  color: #132767;
}
section.o-nas-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) a {
  font-size: 30px;
  color: #132767;
  margin-left: 10px;
  transition: color 350ms;
}
section.o-nas-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) a:hover {
  color: #52c0af;
}
.static-page-section-content {
  padding: 70px 0 100px;
}
.static-page-section-content .section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.static-page-section-content .section-wrapper .backlink {
  position: relative;
  color: #132767;
  font-weight: 700;
  align-self: flex-start;
  font-size: 24px;
  display: block;
  padding-left: 15px;
  transition: transform 300ms;
  transform: translatex(5px);
}
.static-page-section-content .section-wrapper .backlink span {
  position: absolute;
  left: -10px;
  top: 12px;
  font-size: 20px;
  line-height: 15px;
  color: #132767;
  opacity: 0;
  transition: opacity 300ms;
}
.static-page-section-content .section-wrapper .backlink:hover span {
  opacity: 1;
}
.static-page-section-content .section-wrapper .backlink:hover {
  transform: translatex(-3px);
}
.static-page-section-content .section-wrapper .heading {
  text-align: center;
}
.static-page-section-content .section-wrapper .heading .img-absolute {
  left: 50%;
  top: 80%;
  transform: translate(-50%, 50%);
}
.static-page-section-content .section-wrapper .wrapper-text {
  padding-top: 20px;
  width: 100%;
}
.static-page-section-content .section-wrapper .wrapper-text.text-left p {
  text-align: left;
}
.static-page-section-content .section-wrapper .wrapper-text.text-left ul {
  margin-top: 10px;
  list-style-type: none;
  width: 100%;
}
.static-page-section-content .section-wrapper .wrapper-text.text-left ul li {
  position: relative;
  padding: 5px 0 5px 20px;
  display: flex;
}
.static-page-section-content .section-wrapper .wrapper-text.text-left ul li:before {
  position: absolute;
  left: 0;
  top: 6px;
  content: ">";
  font-size: 20px;
  line-height: 25px;
  color: #52c0af;
  font-weight: 900;
}
.static-page-section-content .section-wrapper .wrapper-text p {
  line-height: 32px;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.static-page-section-content .section-wrapper .wrapper-text ul,
.static-page-section-content .section-wrapper .wrapper-text ol {
  padding: 10px 0 0 25px;
}
.static-page-section-content .section-wrapper .wrapper-text ul li,
.static-page-section-content .section-wrapper .wrapper-text ol li {
  padding: 5px 0;
}
.static-page-section-content .section-wrapper .section-equipment {
  padding-top: 80px;
}
.static-page-section-content .section-wrapper .section-equipment .equipment-text h3 {
  font-size: 38px;
  text-align: center;
  margin-bottom: 20px;
}
.static-page-section-content .section-wrapper .section-equipment .equipment-text p {
  line-height: 32px;
  font-size: 18px;
  text-align: center;
}
.static-page-section-content .section-wrapper .section-equipment .equipment-text p:not(:first-of-type) {
  margin-top: 20px;
}
.static-page-section-content .section-wrapper .wrapper-service {
  padding-top: 80px;
}
.static-page-section-content .section-wrapper .wrapper-service h3 {
  font-size: 38px;
  text-align: center;
}
.static-page-section-content .section-wrapper .wrapper-service .service-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.static-page-section-content .section-wrapper .wrapper-service .service-content .service {
  margin-top: 50px;
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: all 250ms;
}
.static-page-section-content .section-wrapper .wrapper-service .service-content .service:hover {
  transform: translateY(-5px);
}
.static-page-section-content .section-wrapper .wrapper-service .service-content .service:hover p {
  color: #132767;
}
.static-page-section-content .section-wrapper .wrapper-service .service-content .service img {
  width: 30px;
  margin-bottom: 10px;
}
.static-page-section-content .section-wrapper .wrapper-service .service-content .service p {
  text-align: center;
  font-weight: 700;
  line-height: 26px;
}
.static-page-section-content .gallery-section-content .section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
}
.static-page-section-content .gallery-section-content .section-wrapper .gallery-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}
.static-page-section-content .gallery-section-content .section-wrapper .gallery-wrapper .flexbox-service {
  position: relative;
  overflow: hidden;
  margin: 7px;
  width: 32%;
  padding-top: 32%;
  border-radius: 20px;
}
.static-page-section-content .gallery-section-content .section-wrapper .gallery-wrapper .flexbox-service:hover img {
  transform: scale(1.03);
}
.static-page-section-content .gallery-section-content .section-wrapper .gallery-wrapper .flexbox-service img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms ease;
  transform: translateZ(0);
  object-fit: cover;
}
.static-page-section-content .gallery-section-content .section-wrapper .gallery-wrapper .service-hidden {
  width: 32%;
  visibility: hidden;
  opacity: 0;
}
.static-page-section-content .cta-section-content {
  padding-top: 100px;
}
.static-page-section-content .cta-section-content .section-wrapper .text {
  display: flex;
  width: 80%;
  padding: 20px 0;
}
.static-page-section-content .cta-section-content .section-wrapper .text:first-of-type {
  justify-content: flex-start;
}
.static-page-section-content .cta-section-content .section-wrapper .text:first-of-type h2 {
  font-size: 44px;
  font-weight: 700;
  line-height: 60px;
  color: #132767;
}
.static-page-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) {
  justify-content: flex-end;
  align-items: center;
}
.static-page-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) h3 {
  font-size: 32px;
  font-weight: 700;
  color: #132767;
}
.static-page-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) a {
  font-size: 30px;
  color: #132767;
  margin-left: 10px;
  transition: color 350ms;
}
.static-page-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) a:hover {
  color: #52c0af;
}
section.cennik-section-content {
  padding: 70px 0 100px 0;
}
section.cennik-section-content .heading .img-absolute {
  left: 50%;
  top: 80%;
  transform: translate(-50%, 50%);
}
section.cennik-section-content .section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.cennik-section-content .section-content .cennik-nav {
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
section.cennik-section-content .section-content .cennik-nav .nav-item {
  text-align: center;
}
section.cennik-section-content .section-content .cennik-nav .nav-item .nav-pill {
  font-size: 14px;
  letter-spacing: 1px;
  padding: 15px 20px;
  text-transform: uppercase;
  color: #212529;
  font-weight: 700;
}
section.cennik-section-content .section-content .cennik-nav .nav-item .nav-pill.active {
  background-color: rgba(19, 39, 103, 0.85);
  border-radius: 30px;
  padding: 15px 20px;
  color: #fff;
  transition: background-color 250ms;
}
section.cennik-section-content .section-content .cennik-nav .nav-item .nav-pill.active:hover {
  background-color: #132767;
}
section.cennik-section-content .section-content .tab-content {
  padding-top: 70px;
  width: 100%;
  text-align: center;
}
section.cennik-section-content .section-content .tab-content .tab-wrapper .text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.cennik-section-content .section-content .tab-content .tab-wrapper .text h3 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}
section.cennik-section-content .section-content .tab-content .tab-wrapper .text .btn {
  margin-top: 50px;
}
section.cennik-section-content .section-content .tab-content .tab-wrapper .text .table {
  display: flex;
  flex-direction: column;
  width: 80%;
}
section.cennik-section-content .section-content .tab-content .tab-wrapper .text .table .table-heading {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  padding: 10px 0;
}
section.cennik-section-content .section-content .tab-content .tab-wrapper .text .table .service {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #52c0af;
}
section.cennik-section-content .section-content .tab-content .tab-wrapper .text .table .service p:first-of-type {
  text-align: left;
}
section.cennik-section-content .section-content .tab-content .tab-wrapper .text .table .service p:nth-of-type(2) {
  text-align: right;
  font-weight: 700;
}
section.kontakt-section-content {
  padding: 70px 0;
}
section.kontakt-section-content .section-wrapper {
  display: flex;
  align-items: center;
}
section.kontakt-section-content .section-wrapper .section-text {
  width: 45%;
  display: flex;
  flex-direction: column;
}
section.kontakt-section-content .section-wrapper .section-text .address {
  margin-bottom: 20px;
}
section.kontakt-section-content .section-wrapper .section-text .address h2 {
  margin-bottom: 10px;
  font-weight: 700;
}
section.kontakt-section-content .section-wrapper .section-text .address p {
  font-weight: 500;
  margin-bottom: 10px;
}
section.kontakt-section-content .section-wrapper .section-text .address a {
  color: #132767;
  font-weight: 700;
  font-size: 15px;
}
section.kontakt-section-content .section-wrapper .section-text .work-hours h3 {
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: 700;
}
section.kontakt-section-content .section-wrapper .section-text .work-hours ul li {
  border-bottom: 1px solid #52c0af;
  padding: 10px 0;
  width: 51%;
}
section.kontakt-section-content .section-wrapper .section-map {
  width: 55%;
  margin-left: 50px;
}
section.kontakt-section-content .section-wrapper .section-map iframe {
  width: 100%;
  height: 330px;
  border-radius: 10px;
}
section.section-footer {
  padding-top: 80px;
  background-color: #f8f8f8;
  border-radius: 100px;
  z-index: 100;
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .img-section-absolute {
  position: absolute;
  top: -70%;
  z-index: 222;
  right: -10%;
  opacity: 0.4;
  width: 13%;
}
section.section-footer .footer-flexbox .flexbox-service .service-logo {
  width: 120px;
}
section.section-footer .footer-flexbox .flexbox-service h2 {
  margin: 10px 0;
  font-weight: 700;
  font-size: 28px;
}
section.section-footer .footer-flexbox .flexbox-service h3 {
  margin: 10px 0;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 1px;
}
section.section-footer .footer-flexbox .flexbox-service p {
  font-size: 15px;
}
section.section-footer .footer-flexbox .flexbox-service:first-of-type p {
  padding: 5px 0;
}
section.section-footer .footer-flexbox .flexbox-service:first-of-type a {
  margin-top: 5px;
  font-weight: 700;
  color: #132767;
  font-size: 14px;
  letter-spacing: 1px;
}
section.section-footer .footer-flexbox .flexbox-service:nth-of-type(3) ul li {
  padding: 8px 0 10px 0;
  border-bottom: 1px solid #52c0af;
}
section.section-footer .footer-flexbox .flexbox-service .footer-heading {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #132767;
  margin-bottom: 25px;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  padding: 5px 0;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 15px;
  color: #212529;
  letter-spacing: 1px;
  transition: color 250ms;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #52c0af;
}
section.section-footer .footer-credits-wrapper {
  margin-top: 50px;
  background-color: #52c0af;
}
section.section-footer .footer-credits {
  border-top: none;
  margin: 0;
  padding: 35px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits p {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  width: 100%;
  text-align: right;
}
section.section-footer .footer-credits p a {
  color: #fff;
  cursor: pointer;
  transition: color 300ms;
  font-weight: 700;
}
section.section-footer .footer-credits p a:hover {
  color: #212529;
}
section.section-footer .alert {
  position: relative;
  margin: 0;
  padding: 40px 0 0 0;
  border-radius: 0;
  background: none;
  border: none;
}
section.section-footer .alert p {
  font-size: 15px;
  color: #fff;
  font-weight: 300;
  text-align: center;
}
section.section-footer .alert .alert-links {
  margin-top: 10px;
  text-align: center;
}
section.section-footer .alert .alert-links a {
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 700;
  margin-right: 10px;
  font-size: 14px;
}
section.section-footer .alert .alert-links a:hover {
  color: #212529;
}
@media (max-width: 1285px) {
  section.o-nas-section-content .team-section-content .wave-bg svg {
    top: -225px;
  }
  section.o-nas-section-content .gallery-section-content .wave-bg svg {
    top: -125px;
  }
}
@media (max-width: 991.98px) {
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-top .flexbox-nav li {
    font-size: 12px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav {
    width: 100%;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-logo {
    width: 20%;
  }
  .heading h2 {
    font-size: 42px;
  }
  section.section-welcome .section-wrapper {
    padding-bottom: 100px;
  }
  section.section-welcome .section-wrapper .welcome-text {
    padding-top: 50px;
  }
  section.section-welcome .section-wrapper .welcome-text h1 {
    font-size: 45px;
  }
  section.section-welcome .section-wrapper .welcome-text span {
    font-size: 16px;
  }
  section.section-welcome .section-wrapper .welcome-img img {
    transform: translate(0%, 12%);
  }
  section.section-offer .tab-content .tab-wrapper .image {
    width: 40%;
  }
  section.section-offer .tab-content .tab-wrapper .text {
    width: 60%;
  }
  section.section-icons .icons-wrapper .wrapper-content {
    width: 70%;
  }
  section.section-icons .icons-wrapper .wrapper-image {
    width: 30%;
  }
  section.section-icons .icons-wrapper .wrapper-image img {
    width: 100%;
  }
  section.o-nas-section-content .team-section-content .team-content .person .person-text {
    padding: 0 0 0 70px;
  }
  section.section-team .wave-bg svg {
    top: -200px;
  }
  section.section-team .team-wrapper .team-content {
    padding-top: 0;
  }
  section.section-team .team-wrapper .team-content .person {
    width: 47%;
    margin-top: 40px;
  }
  section.section-faq .accordion {
    width: 80%;
    padding-right: 0;
  }
  section.section-faq .faq-image {
    display: none;
  }
  section.o-nas-section-content .team-section-content .wave-bg svg {
    top: -170px;
  }
  section.cennik-section-content .section-content .cennik-nav .nav-item {
    margin-top: 20px;
  }
  section.cennik-section-content .section-content .cennik-nav .nav-item .nav-pill {
    font-size: 13px;
  }
  section.cennik-section-content .section-content .cennik-nav .nav-item .nav-pill.active {
    padding: 10px 15px;
  }
  section.cennik-section-content .section-content .tab-content .tab-wrapper .text .table {
    width: 100%;
  }
  section.cennik-section-content .section-content .tab-content .tab-wrapper .text .table .service p:first-of-type {
    width: 65%;
  }
  section.cennik-section-content .section-content .tab-content .tab-wrapper .text .table .service p:nth-of-type(2) {
    width: 35%;
  }
  .faq-section-content .accordion-faq-content {
    padding: 50px 0 0 0;
  }
  .faq-section-content .accordion-faq-content .accordion-nav .nav-item {
    margin-top: 20px;
  }
  .faq-section-content .accordion-faq-content .accordion-nav .nav-item .nav-pill {
    font-size: 13px;
  }
  .faq-section-content .accordion-faq-content .accordion-nav .nav-item .nav-pill.active {
    padding: 10px 15px;
  }
  section.kontakt-section-content .section-wrapper .section-map {
    margin-left: 30px;
  }
  section.section-footer {
    padding-top: 50px;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.section-footer .footer-flexbox .img-section-absolute {
    top: -20%;
    right: -5%;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    margin-top: 20px;
  }
  section.section-footer .footer-credits-wrapper {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  nav.section-header .header-flexbox {
    padding: 10px;
    border-bottom: 1px solid #52c0af;
  }
  nav.section-header .header-flexbox .flexbox-logo.sm-screen {
    display: block;
    width: auto;
  }
  nav.section-header .header-flexbox .flexbox-logo.sm-screen img {
    width: 70px;
  }
  nav.section-header .header-flexbox .nav-button {
    display: flex;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: flex;
    justify-content: flex-end;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-top {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom {
    background-color: #fff;
    height: 100%;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .container {
    height: 100%;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper {
    height: 100%;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-logo {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav {
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav li {
    color: #212529;
    padding: 20px 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner.inner-bottom .inner-wrapper .flexbox-nav li a {
    color: #212529;
    font-size: 15px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  nav.section-header .header-flexbox .contact-mobile {
    display: flex;
    width: 70%;
    padding: 0 10px;
  }
  nav.section-header .header-flexbox .contact-mobile .flexbox-nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  nav.section-header .header-flexbox .contact-mobile .flexbox-nav li {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    letter-spacing: 1px;
  }
  nav.section-header .header-flexbox .contact-mobile .flexbox-nav li a {
    color: #212529;
    margin: 0 20px;
  }
  nav.section-header .header-flexbox .contact-mobile .flexbox-nav li a img {
    width: 20px;
  }
  .heading .img-absolute {
    left: 50%;
    transform: translate(-50%, 50%);
  }
  section.section-welcome .section-wrapper .welcome-text h1 {
    font-size: 34px;
  }
  section.section-welcome .section-wrapper .welcome-text span {
    font-size: 15px;
  }
  section.section-welcome .section-wrapper .welcome-img img {
    transform: translate(0%, 20%);
  }
  section.section-about .about-wrapper {
    flex-direction: column;
  }
  section.section-about .about-wrapper .img-section-absolute {
    display: none;
  }
  section.section-about .about-wrapper .wrapper-text {
    text-align: center;
    width: 100%;
    margin-top: 40px;
  }
  section.section-about .about-wrapper .wrapper-text ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  section.section-about .about-wrapper .wrapper-image {
    width: 100%;
  }
  section.section-icons .icons-wrapper {
    flex-direction: column;
  }
  section.section-icons .icons-wrapper .wrapper-content {
    width: 100%;
    text-align: center;
  }
  section.section-icons .icons-wrapper .wrapper-content .content-icons .icon img {
    width: 50px;
  }
  section.section-icons .icons-wrapper .wrapper-image {
    display: none;
  }
  section.section-offer .offer-nav,
  section.offer-section-content .offer-nav {
    flex-direction: column;
    align-items: center;
  }
  section.section-offer .offer-nav .nav-item:not(:first-of-type),
  section.offer-section-content .offer-nav .nav-item:not(:first-of-type) {
    margin-top: 20px;
  }
  section.section-offer .offer-nav .nav-item .nav-pill,
  section.offer-section-content .offer-nav .nav-item .nav-pill {
    font-size: 15px;
  }
  section.section-offer .offer-nav .nav-item .nav-pill.active,
  section.offer-section-content .offer-nav .nav-item .nav-pill.active {
    padding: 15px 20px;
  }
  section.section-offer .tab-content .tab-wrapper .image,
  section.offer-section-content .tab-content .tab-wrapper .image {
    display: none;
  }
  section.section-offer .tab-content .tab-wrapper .text,
  section.offer-section-content .tab-content .tab-wrapper .text {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  section.section-offer .tab-content .tab-wrapper .text ul,
  section.offer-section-content .tab-content .tab-wrapper .text ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  section.section-faq .btn-default {
    align-self: center;
  }
  .faq-section-content .accordion-faq-content .accordion-nav {
    flex-direction: column;
    align-items: center;
  }
  .faq-section-content .accordion-faq-content .accordion-nav .nav-item {
    text-align: center;
  }
  .faq-section-content .accordion-faq-content .accordion-nav .nav-item:not(:first-of-type) {
    margin-top: 40px;
  }
  .faq-section-content .accordion-faq-content .accordion-nav .nav-item .nav-pill.active {
    padding: 15px 20px;
  }
  .faq-section-content .accordion-faq-content .accordion-item .item-description {
    text-align: center;
    padding: 40px 0;
  }
  .faq-section-content .accordion-faq-content .accordion-item button span {
    text-align: center;
  }
  .faq-section-content .accordion-faq-content .accordion-item button.collapsed span {
    text-align: left;
    padding: 0 20px 0 10px;
    font-size: 13px;
  }
  section.cennik-section-content {
    padding: 70px 0;
  }
  section.cennik-section-content .section-content .cennik-nav {
    flex-direction: column;
    align-items: center;
  }
  section.cennik-section-content .section-content .cennik-nav .nav-item:not(:first-of-type) {
    margin-top: 40px;
  }
  section.cennik-section-content .section-content .cennik-nav .nav-item .nav-pill {
    font-size: 15px;
  }
  section.cennik-section-content .section-content .cennik-nav .nav-item .nav-pill.active {
    padding: 15px 20px;
  }
  section.o-nas-section-content .team-section-content .wave-bg svg,
  .static-page-section-content .team-section-content .wave-bg svg {
    top: -120px;
  }
  section.o-nas-section-content .team-section-content .team-content .person,
  .static-page-section-content .team-section-content .team-content .person,
  section.o-nas-section-content .team-section-content .team-content .person:nth-of-type(even),
  .static-page-section-content .team-section-content .team-content .person:nth-of-type(even) {
    flex-direction: column;
    padding: 30px 0;
  }
  section.o-nas-section-content .team-section-content .team-content .person .person-image,
  .static-page-section-content .team-section-content .team-content .person .person-image,
  section.o-nas-section-content .team-section-content .team-content .person:nth-of-type(even) .person-image,
  .static-page-section-content .team-section-content .team-content .person:nth-of-type(even) .person-image {
    width: 70%;
    justify-content: center;
  }
  section.o-nas-section-content .team-section-content .team-content .person .person-text,
  .static-page-section-content .team-section-content .team-content .person .person-text,
  section.o-nas-section-content .team-section-content .team-content .person:nth-of-type(even) .person-text,
  .static-page-section-content .team-section-content .team-content .person:nth-of-type(even) .person-text {
    width: 70%;
    padding: 30px 0 0 0;
    text-align: center;
  }
  section.o-nas-section-content .team-section-content .team-content .person .person-text ul,
  .static-page-section-content .team-section-content .team-content .person .person-text ul,
  section.o-nas-section-content .team-section-content .team-content .person:nth-of-type(even) .person-text ul,
  .static-page-section-content .team-section-content .team-content .person:nth-of-type(even) .person-text ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  section.o-nas-section-content .section-wrapper .backlink,
  .static-page-section-content .section-wrapper .backlink {
    margin-bottom: 25px;
    align-self: center;
    transform: translatex(0);
    padding-left: 0;
  }
  section.o-nas-section-content .section-wrapper .backlink span,
  .static-page-section-content .section-wrapper .backlink span {
    display: none;
  }
  section.o-nas-section-content .section-wrapper .backlink:hover,
  .static-page-section-content .section-wrapper .backlink:hover {
    transform: translatex(0);
  }
  section.o-nas-section-content .section-wrapper .wrapper-service .service-content .service,
  .static-page-section-content .section-wrapper .wrapper-service .service-content .service {
    width: 50%;
  }
  section.o-nas-section-content .icons-section-content .icons-wrapper,
  .static-page-section-content .icons-section-content .icons-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.o-nas-section-content .icons-section-content .icons-wrapper .icon,
  .static-page-section-content .icons-section-content .icons-wrapper .icon {
    width: 50%;
  }
  section.o-nas-section-content .gallery-section-content .section-wrapper,
  .static-page-section-content .gallery-section-content .section-wrapper {
    padding-top: 70px;
  }
  section.o-nas-section-content .gallery-section-content .section-wrapper .gallery-wrapper .flexbox-service-wrapper,
  .static-page-section-content .gallery-section-content .section-wrapper .gallery-wrapper .flexbox-service-wrapper {
    width: 47%;
  }
  section.o-nas-section-content .cta-section-content .section-wrapper .text,
  .static-page-section-content .cta-section-content .section-wrapper .text {
    width: 100%;
  }
  section.o-nas-section-content .cta-section-content .section-wrapper .text:first-of-type h2,
  .static-page-section-content .cta-section-content .section-wrapper .text:first-of-type h2 {
    font-size: 38px;
  }
  section.o-nas-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) h3,
  .static-page-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) h3 {
    font-size: 28px;
  }
  section.kontakt-section-content .section-wrapper {
    flex-direction: column;
    align-items: center;
  }
  section.kontakt-section-content .section-wrapper .section-text {
    width: 100%;
    text-align: center;
  }
  section.kontakt-section-content .section-wrapper .section-text ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  section.kontakt-section-content .section-wrapper .section-map {
    width: 100%;
    margin-left: 0;
    margin-top: 50px;
  }
  section.kontakt-section-content .section-wrapper .section-map iframe {
    height: 250px;
  }
  section.section-footer {
    padding-top: 50px;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.section-footer .footer-flexbox .img-section-absolute {
    top: -20%;
    width: 30%;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    text-align: center;
    margin-top: 20px;
  }
  section.section-footer .footer-credits p,
  section.section-footer .alert p {
    text-align: center;
  }
}
@media (max-width: 680.98px) {
  section.section-team .wave-bg svg {
    top: -170px;
  }
}
@media (max-width: 575.98px) {
  .heading {
    margin-bottom: 25px;
  }
  .heading h2 {
    font-size: 32px;
  }
  .heading .img-absolute {
    bottom: 0;
    width: 70%;
  }
  section.section-welcome {
    padding-top: 60px;
  }
  section.section-welcome .section-wrapper {
    justify-content: center;
  }
  section.section-welcome .section-wrapper .welcome-text {
    width: 80%;
    padding-top: 0;
  }
  section.section-welcome .section-wrapper .welcome-text h1 {
    font-size: 45px;
  }
  section.section-welcome .section-wrapper .welcome-img {
    display: none;
  }
  section.section-icons {
    padding-top: 70px;
  }
  section.section-icons .icons-wrapper {
    flex-direction: column;
  }
  section.section-icons .icons-wrapper .wrapper-content {
    padding-right: 0;
  }
  section.section-icons .icons-wrapper .wrapper-content .content-icons {
    flex-direction: column;
    align-items: center;
  }
  section.section-icons .icons-wrapper .wrapper-content .content-icons .icon {
    width: 55%;
  }
  section.section-icons .icons-wrapper .wrapper-content .content-icons .icon p {
    font-size: 17px;
  }
  section.section-offer,
  section.offer-section-content {
    padding: 70px 0 100px 0;
  }
  section.section-offer .tab-content .tab-wrapper .text ul,
  section.offer-section-content .tab-content .tab-wrapper .text ul {
    align-items: flex-start;
    text-align: left;
  }
  section.section-team {
    margin-top: 0;
  }
  section.section-team .team-wrapper .team-content {
    flex-direction: column;
  }
  section.section-team .team-wrapper .team-content .person {
    width: 65%;
  }
  section.section-team .team-wrapper .team-content .person img {
    opacity: 1;
  }
  section.section-team .team-wrapper .team-content .person .text {
    background-color: rgba(255, 255, 255, 0.9);
  }
  section.o-nas-section-content,
  .static-page-section-content {
    padding: 70px 0;
  }
  section.o-nas-section-content .section-wrapper .wrapper-text.text-left p,
  .static-page-section-content .section-wrapper .wrapper-text.text-left p {
    text-align: center;
  }
  section.o-nas-section-content .section-wrapper .wrapper-service .service-content,
  .static-page-section-content .section-wrapper .wrapper-service .service-content {
    justify-content: center;
  }
  section.o-nas-section-content .section-wrapper .wrapper-service .service-content .service,
  .static-page-section-content .section-wrapper .wrapper-service .service-content .service {
    width: 80%;
  }
  section.o-nas-section-content .gallery-section-content .wave-bg svg,
  .static-page-section-content .gallery-section-content .wave-bg svg {
    top: -85px;
  }
  section.o-nas-section-content .gallery-section-content .section-wrapper,
  .static-page-section-content .gallery-section-content .section-wrapper {
    padding-top: 0;
  }
  section.o-nas-section-content .gallery-section-content .section-wrapper .gallery-wrapper,
  .static-page-section-content .gallery-section-content .section-wrapper .gallery-wrapper {
    padding-top: 50px;
    justify-content: center;
  }
  section.o-nas-section-content .gallery-section-content .section-wrapper .gallery-wrapper .flexbox-service-wrapper,
  .static-page-section-content .gallery-section-content .section-wrapper .gallery-wrapper .flexbox-service-wrapper {
    width: 90%;
  }
  section.o-nas-section-content .team-section-content,
  .static-page-section-content .team-section-content {
    margin-top: 150px;
  }
  section.o-nas-section-content .team-section-content .wave-bg svg,
  .static-page-section-content .team-section-content .wave-bg svg {
    top: -90px;
  }
  section.o-nas-section-content .cta-section-content .section-wrapper .text,
  .static-page-section-content .cta-section-content .section-wrapper .text {
    flex-direction: column;
  }
  section.o-nas-section-content .cta-section-content .section-wrapper .text:first-of-type h2,
  .static-page-section-content .cta-section-content .section-wrapper .text:first-of-type h2 {
    text-align: center;
    font-size: 28px;
    line-height: 38px;
  }
  section.o-nas-section-content .cta-section-content .section-wrapper .text:nth-of-type(2),
  .static-page-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) {
    text-align: center;
  }
  section.o-nas-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) h3,
  .static-page-section-content .cta-section-content .section-wrapper .text:nth-of-type(2) h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  section.section-footer .footer-flexbox .img-section-absolute {
    right: 3%;
    width: 25%;
  }
}
@media (max-width: 530px) {
  section.section-team .wave-bg svg {
    top: -120px;
  }
  section.section-team .team-wrapper .team-content .person {
    width: 70%;
  }
  section.o-nas-section-content .team-section-content .team-content .person,
  .static-page-section-content .team-section-content .team-content .person,
  section.o-nas-section-content .team-section-content .team-content .person:nth-of-type(even),
  .static-page-section-content .team-section-content .team-content .person:nth-of-type(even) {
    justify-content: center;
  }
  section.o-nas-section-content .team-section-content .team-content .person .person-image,
  .static-page-section-content .team-section-content .team-content .person .person-image,
  section.o-nas-section-content .team-section-content .team-content .person:nth-of-type(even) .person-image,
  .static-page-section-content .team-section-content .team-content .person:nth-of-type(even) .person-image {
    height: 350px;
    width: auto;
  }
  section.section-faq {
    padding-bottom: 70px;
  }
  section.section-faq .accordion {
    width: 100%;
  }
  section.section-faq .accordion .accordion-item .item-description {
    text-align: center;
    padding: 20px 0 30px 0;
  }
  section.section-faq .accordion .accordion-item button span {
    text-align: center;
  }
  section.section-faq .accordion .accordion-item button.collapsed span {
    text-align: left;
    font-size: 13px;
  }
  section.section-footer .footer-flexbox .img-section-absolute {
    top: -12%;
  }
}
@media (max-width: 400px) {
  section.section-welcome .wave-bg svg {
    bottom: 20px;
  }
  section.section-icons .icons-wrapper .wrapper-content .content-icons .icon {
    width: 80%;
  }
  section.o-nas-section-content {
    padding: 50px 0;
  }
  section.o-nas-section-content .team-section-content {
    margin-top: 100px;
  }
  section.o-nas-section-content .team-section-content .wave-bg svg {
    top: -70px;
  }
  section.o-nas-section-content .team-section-content .team-content .person .person-text,
  section.o-nas-section-content .team-section-content .team-content .person:nth-of-type(even) .person-text {
    width: 85%;
  }
  section.o-nas-section-content .icons-section-content {
    padding-top: 40px;
  }
  section.o-nas-section-content .icons-section-content .icons-wrapper .icon {
    width: 80%;
  }
}
