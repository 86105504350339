@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap&subset=latin-ext");

body {
  min-height: 100vh;
  height: 100%;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  .pt-50 {
    padding-top: 50px;
  }

  .pt-120 {
    padding-top: 120px;
  }
}

// variables

@color1: #52c0af;
@color2: #132767;

.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;

  .alert-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    transform: translateX(8px);
    padding: 50px 0;

    .service-inner {
      padding: 50px;
      background: #fff;
      width: 100%;
      box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
      text-align: center;
      border-top: 5px solid #fff;
      position: relative;
      transform: scale(0.8);
      transition: all 0.5s;

      .inner-dismiss {
        top: 10px;
        right: 10px;
        position: absolute;
        border: 0;
        background: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        padding: 5px;
        outline: none;

        img {
          height: 15px;
        }
      }

      &.success {
        border-top: 5px solid #78b042;
      }

      &.warning {
        border-top: 5px solid #d51a27;
      }

      &.info {
        border-top: 5px solid #1a88d5;
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .alert-service {
      .service-inner {
        transform: none;
      }
    }
  }
}

.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-service {
    padding: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .service-inner {
      background: #fff;
      width: 700px;
      max-width: 100%;
      padding: 50px 25px 25px 25px;
      text-align: center;
      position: relative;

      .service-button {
        position: absolute;
        top: 20px;
        right: 20px;
        border: none;
        background: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-bar {
          display: block;
          position: absolute;
          width: 20px;
          height: 2px;
          background: #222;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }

      .service-content {
        .heading {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .btn {
          display: flex;
          justify-content: center;
          margin-top: 5px;
        }
      }

      .service-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-link {
          padding: 0;
          text-transform: uppercase;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

.btn {
  border: none;
  background: none;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;

  &.btn-default {
    background-color: rgba(@color2, 0.85);
    border-radius: 30px;
    padding: 13px 20px;
    color: #fff;
    transition: background-color 250ms;

    &:hover {
      background-color: @color2;
    }
  }
}

.heading {
  position: relative;
  padding: 5px 0;
  margin-bottom: 35px;

  h2 {
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }

  .img-absolute {
    position: absolute;
    bottom: -15px;
    left: 0;
    opacity: 0.8;
  }
}

nav.section-header {
  .header-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexbox-logo {
      width: 22%;

      img {
        width: 80px;
      }

      &.sm-screen {
        display: none;
      }
    }

    .flexbox-nav-wrapper {
      width: 100%;

      .nav-button-close {
        position: absolute;
        display: none;
        width: 100%;
        top: 20px;
        right: 20px;

        .container {
          display: flex;
          justify-content: flex-end;

          &:before,
          &:after {
            content: none;
          }
        }

        button {
          outline: none;
          border: none;
          background: none;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          .button-bar {
            display: block;
            position: absolute;
            width: 30px;
            height: 3px;
            background: #222;

            &:nth-child(1) {
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
        }
      }

      .flexbox-nav-inner {
        &.inner-top {
          padding: 15px 0;
          background-color: @color1;

          .flexbox-nav {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            li {
              display: flex;
              color: #fff;
              font-size: 13px;
              letter-spacing: 1px;

              img {
                width: 16px;
                margin-right: 5px;
              }

              a {
                color: #fff;
                transition: 250ms color;

                &:hover {
                  color: @color2;
                }
              }
            }
          }
        }

        &.inner-bottom {
          padding: 15px 0;
          background-color: #fff;

          .inner-wrapper {
            display: flex;
            justify-content: space-between;

            .flexbox-nav {
              display: flex;
              width: 65%;
              justify-content: space-between;
              align-items: center;

              li {
                a {
                  color: #212529;
                  font-size: 13px;
                  letter-spacing: 1px;
                  text-transform: uppercase;
                  font-weight: 900;
                  transition: color 250ms;

                  &:hover {
                    color: @color1;
                  }
                }

                &.active {
                  border: 1px solid @color2;
                  border-radius: 20px;
                  padding: 2px 12px;
                  transition: all 250ms;

                  &:hover {
                    background: @color1;
                    border: 1px solid transparent;
                  }

                  &:hover a {
                    color: #fff;
                  }
                }
              }

              .active {
                border-bottom: 1px solid @color2;
                padding-bottom: 8px;
                transition: all 250ms;
              }
            }

            .flexbox-logo {
              width: 40%;

              img {
                width: 80px;
              }
            }
          }
        }
      }
    }

    .contact-mobile {
      display: none;
    }

    .nav-button {
      padding: 10px;
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;

      .button-bar {
        height: 2px;
        width: 24px;
        background: @color1;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 4px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 4px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }
    }
  }
}

section.section-welcome {
  position: relative;
  padding-top: 70px;
  background-color: @color1;

  .wave-bg {
    position: relative;
    width: 100%;
    padding-top: 8%;
    background: #fff;

    svg {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }

  .section-wrapper {
    display: flex;

    .welcome-text {
      padding-top: 120px;
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        color: #fff;
        font-size: 60px;
        font-weight: 900;
        margin-bottom: 20px;
      }

      p:first-of-type {
        display: inline-block;
        font-size: 17px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 20px;
        font-weight: 700;
      }

      p:nth-of-type(2) {
        color: #fff;
        margin-top: 10px;
        letter-spacing: 1px;
        line-height: 26px;
      }

      .btn {
        margin-top: 50px;
      }
    }

    .welcome-img {
      width: 40%;

      img {
        width: 100%;
      }
    }
  }
}

section.section-about {
  position: relative;

  .about-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .img-section-absolute {
      position: fixed;
      top: 30%;
      z-index: 11;
      left: -4%;
      opacity: 0.4;
      width: 12%;
    }

    .wrapper-image {
      width: 50%;

      img {
        width: 100%;
        border-radius: 10px;
      }
    }

    .wrapper-text {
      width: 45%;

      p {
        line-height: 30px;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      h3 {
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 700;
        padding: 0;
        margin: 10px 0;
      }

      ul {
        margin-top: 10px;
        list-style-type: none;

        li {
          position: relative;
          padding: 5px 0 5px 20px;
          display: flex;

          &:before {
            position: absolute;
            left: 0;
            top: 6px;
            content: ">";
            font-size: 20px;
            line-height: 25px;
            color: @color1;
            font-weight: 900;
          }
        }
      }

      .btn {
        margin-top: 30px;
      }
    }
  }
}

section.section-icons {
  padding-top: 120px;

  .icons-wrapper {
    display: flex;
    align-items: center;

    .wrapper-image {
      width: 50%;

      img {
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }

    .wrapper-content {
      width: 50%;
      padding-right: 40px;

      .content-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        .icon {
          width: 47%;
          display: flex;
          align-items: flex-start;
          margin-top: 40px;
          transition: all 350ms;

          img {
            width: 40px;
            margin-right: 16px;
          }

          p {
            text-align: left;
            font-weight: 700;
            line-height: 30px;
          }

          &:hover {
            transform: translateY(-5px);
          }

          &:hover p {
            color: @color2;
          }
        }
      }

      .btn {
        margin-top: 50px;
      }
    }
  }
}

section.section-offer,
.offer-section-content {
  padding: 100px 0 150px 0;

  .heading {
    h2 {
      text-align: center;
    }
  }

  .offer-nav {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

    .nav-item {
      text-align: center;

      .nav-pill {
        font-size: 14px;
        letter-spacing: 1px;
        padding: 15px 20px;
        text-transform: uppercase;
        color: #212529;
        font-weight: 700;

        &.active {
          background-color: rgba(@color2, 0.85);
          border-radius: 30px;
          padding: 15px 20px;
          color: #fff;
          transition: background-color 250ms;

          &:hover {
            background-color: @color2;
          }
        }
      }
    }
  }

  .tab-content {
    padding-top: 80px;

    .tab-wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 40px;

      .text {
        padding-left: 50px;
        width: 50%;

        p {
          line-height: 30px;
        }

        h3 {
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 20px;
        }

        h4 {
          font-size: 20px;
          font-weight: 700;
          margin: 10px 0;
        }

        ul {
          list-style-type: none;

          li {
            position: relative;
            padding: 5px 0px 5px 20px;
            display: flex;

            &:before {
              position: absolute;
              left: 0;
              top: 8px;
              content: ">";
              font-size: 18px;
              line-height: 20px;
              color: @color1;
              font-weight: 900;
            }
          }
        }
      }

      .image {
        width: 50%;

        img {
          width: 100%;
          border-radius: 10px;
        }
      }

      .btn {
        margin-top: 30px;
      }
    }
  }
}

.offer-section-content {
  .heading {
    .img-absolute {
      left: 50%;
      top: 50%;
      transform: translate(-50%, 80%);
    }
  }

  .offer-content {
    padding-top: 80px;
  }

  .offer-text {
    padding-top: 20px;

    p {
      line-height: 32px;
      font-size: 18px;
      text-align: center;
      margin-top: 10px;
    }
  }
}

section.section-team {
  position: relative;
  z-index: 111;
  padding: 50px 0;
  margin-top: 60px;
  background-color: #f8f8f8;

  .wave-bg {
    position: relative;
    width: 100%;

    svg {
      display: block;
      position: absolute;
      top: -300px;
      left: 0;
    }
  }

  .team-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .team-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      width: 100%;

      .person {
        width: 24%;
        height: 350px;
        position: relative;
        margin: 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 100%;
          transition: opacity 350ms ease-in-out;
          opacity: 0.8;
          border-radius: 10px;
        }

        &:hover img {
          opacity: 1;
        }

        &:hover .text {
          background-color: #fff;
        }

        .text {
          position: absolute;
          width: 100%;
          bottom: 0;
          padding: 20px;
          background-color: rgba(#fff, 0.8);
          transition: background-color 350ms ease-in-out;

          .name {
            font-size: 20px;
            font-weight: 700;
          }

          .position {
            color: @color2;
            font-weight: 700;
            letter-spacing: 1px;
          }

          .description {
            margin-top: 10px;
            font-size: 14px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

section.section-partners {
  position: relative;
  z-index: 111;
  padding: 50px 0 100px 0;
  background-color: #f8f8f8;

  .partners-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .owl-carousel-cooperation {
    margin-top: 50px;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 60%;
        opacity: 0.6;
        transition: opacity 350ms;

        &:hover {
          opacity: 1;
        }
      }
    }

    .owl-nav {
      display: none;
    }

    .owl-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 60px;

      .owl-dot {
        &.active {
          span {
            background: @color2;
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}

section.section-faq,
.faq-section-content {
  padding-bottom: 120px;
  width: 100%;

  .wave-bg {
    position: relative;
    z-index: -1;
    width: 100%;
    padding-top: 10%;
    background: #fff;

    svg {
      display: block;
      position: absolute;
      bottom: -15%;
      left: 0;
    }
  }

  .faq-wrapper {
    display: flex;
    flex-direction: column;
  }

  .faq-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accordion {
    width: 55%;
  }

  .accordion,
  .accordion-faq-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 50px;

    .accordion-nav {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .nav-item {
        text-align: center;

        .nav-pill {
          font-size: 14px;
          letter-spacing: 1px;
          padding: 15px 20px;
          text-transform: uppercase;
          color: #212529;
          font-weight: 700;

          &.active {
            background-color: rgba(@color2, 0.85);
            border-radius: 30px;
            padding: 15px 20px;
            color: #fff;
            transition: background-color 250ms;

            &:hover {
              background-color: @color2;
            }
          }
        }
      }
    }

    .accordion-item {
      .item-description {
        padding: 20px 0 30px 15px;

        p {
          font-size: 15px;
          line-height: 26px;
          margin-top: 10px;
        }

        .btn-faq {
          display: inline-block;
          color: @color2;
          font-size: 15px;
          margin-top: 15px;
          font-weight: 700;
          transition: all 250ms;

          &:hover {
            color: @color1;
          }
        }
      }

      .collapsed {
        background-color: #f8f8f8;
        margin-bottom: 10px;
        color: #020202;
        display: block;

        span {
          &:after {
            position: absolute;
            right: 0;
            top: 0;
            content: ">";
            font-size: 20px;
            line-height: 20px;
            color: @color2;
            font-weight: 900;
          }
        }
      }

      button {
        cursor: pointer;
        border: none;
        outline: none;
        background-color: rgba(@color2, 0.85);
        border-radius: 30px;
        color: #fff;
        width: 100%;
        padding: 15px 10px;

        span {
          position: relative;
          display: block;
          width: 100%;
          text-align: left;
          padding: 0 20px;
          letter-spacing: 0.5px;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
    }
  }

  .accordion-faq-content {
    width: 100%;
    padding: 80px 0 0 0;

    .accordion-item {
      button {
        border-radius: 10px;
      }
    }
  }

  .faq-image {
    width: 45%;

    img {
      width: 100%;
      border-radius: 10px;
    }
  }

  .btn-default {
    margin-top: 30px;
    align-self: flex-start;
  }
}

.faq-section-content {
  padding-bottom: 0;
}

section.o-nas-section-content {
  padding: 50px 0 100px 0;

  .section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    .heading {
      .img-absolute {
        left: 50%;
        top: 80%;
        transform: translate(-50%, 50%);
      }
    }

    .wrapper-text {
      padding-top: 20px;

      p {
        line-height: 32px;
        font-size: 18px;
        text-align: center;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }
    }
  }

  .team-section-content {
    position: relative;
    margin-top: 200px;
    background-color: #f8f8f8;

    .wave-bg {
      position: relative;
      width: 100%;

      svg {
        display: block;
        position: absolute;
        top: -290px;
        left: 0;
        z-index: -1;
      }
    }

    .team-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .person {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 0;

        .person-image {
          width: 35%;
          height: 450px;
          display: flex;
          justify-content: flex-end;

          img {
            height: 100%;
            border-radius: 10px;
          }
        }

        .person-text {
          width: 60%;
          padding: 0 90px 0 70px;

          .name {
            font-size: 30px;
            font-weight: 700;
          }

          .position {
            color: @color2;
            font-weight: 700;
            letter-spacing: 1px;
          }

          .description {
            p {
              line-height: 28px;
              margin-top: 10px;
            }

            ul {
              margin-top: 10px;
              list-style-type: none;

              li:first-of-type {
                margin-bottom: 10px;
                font-weight: 700;
                padding: 0;
                font-size: 20px;

                &:before {
                  display: none;
                }
              }

              li {
                position: relative;
                padding: 5px 0 5px 20px;
                display: flex;

                &:before {
                  position: absolute;
                  left: 0;
                  top: 6px;
                  content: ">";
                  font-size: 20px;
                  line-height: 25px;
                  color: @color1;
                  font-weight: 900;
                }
              }
            }
          }
        }

        &:nth-of-type(even) {
          flex-direction: row-reverse;

          .person-image {
            justify-content: flex-start;
          }

          .person-text {
            padding: 0 70px 0 90px;
          }
        }
      }
    }
  }

  .icons-section-content {
    padding-top: 70px;
    background-color: #f8f8f8;

    .heading {
      .img-absolute {
        left: 50%;
        top: 80%;
        transform: translate(-50%, 50%);
      }

      h2 {
        text-align: center;
      }
    }

    .icons-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .icon {
        width: 32%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        transition: all 250ms;

        img {
          width: 50px;
        }

        p {
          text-align: center;
          margin-top: 10px;
          transition: all 250ms;
          font-weight: 700;
        }

        &:hover {
          transform: translateY(-5px);
        }

        &:hover p {
          color: @color2;
        }
      }
    }
  }

  .gallery-section-content {
    padding-top: 70px;

    .wave-bg {
      position: relative;
      width: 100%;

      svg {
        display: block;
        position: absolute;
        top: -220px;
        left: 0;
        z-index: -1;
      }
    }

    .section-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 100px;

      .heading {
        .img-absolute {
          left: 50%;
          top: 80%;
          transform: translate(-50%, 50%);
        }
      }

      .gallery-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-top: 20px;
        width: 100%;

        .flexbox-service {
          position: relative;
          overflow: hidden;
          margin: 7px;
          width: 32%;
          padding-top: 32%;
          border-radius: 20px;

          &:hover img {
            transform: scale(1.03);
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 250ms ease;
            transform: translateZ(0);
            object-fit: cover;
          }
        }

        .service-hidden {
          width: 32%;
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }

  .cta-section-content {
    padding-top: 50px;

    .section-wrapper {
      .text {
        display: flex;
        width: 80%;
        padding: 20px 0;

        &:first-of-type {
          justify-content: flex-start;

          h2 {
            font-size: 44px;
            line-height: 60px;
            font-weight: 700;
            color: @color2;
          }
        }

        &:nth-of-type(2) {
          justify-content: flex-end;
          align-items: center;

          h3 {
            font-size: 32px;
            font-weight: 700;
            color: @color2;
          }

          a {
            font-size: 30px;
            color: @color2;
            margin-left: 10px;
            transition: color 350ms;

            &:hover {
              color: @color1;
            }
          }
        }
      }
    }
  }
}

.static-page-section-content {
  padding: 70px 0 100px;

  .section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .backlink {
      position: relative;
      color: @color2;
      font-weight: 700;
      align-self: flex-start;
      font-size: 24px;
      display: block;
      padding-left: 15px;
      transition: transform 300ms;
      transform: translatex(5px);

      span {
        position: absolute;
        left: -10px;
        top: 12px;
        font-size: 20px;
        line-height: 15px;
        color: @color2;
        opacity: 0;
        transition: opacity 300ms;
      }

      &:hover span {
        opacity: 1;
      }

      &:hover {
        transform: translatex(-3px);
      }
    }

    .heading {
      text-align: center;

      .img-absolute {
        left: 50%;
        top: 80%;
        transform: translate(-50%, 50%);
      }
    }

    .wrapper-text {
      padding-top: 20px;
      width: 100%;
      //   display: flex;
      //   flex-direction: column;
      //   align-items: center;

      &.text-left {
        p {
          text-align: left;
        }

        ul {
          margin-top: 10px;
          list-style-type: none;
          width: 100%;

          li {
            position: relative;
            padding: 5px 0 5px 20px;
            display: flex;

            &:before {
              position: absolute;
              left: 0;
              top: 6px;
              content: ">";
              font-size: 20px;
              line-height: 25px;
              color: @color1;
              font-weight: 900;
            }
          }
        }
      }

      p {
        line-height: 32px;
        font-size: 18px;
        text-align: center;
        margin-top: 10px;
      }

      ul,
      ol {
        padding: 10px 0 0 25px;

        li {
          padding: 5px 0;
        }
      }
    }

    .section-equipment {
      padding-top: 80px;

      .equipment-text {
        h3 {
          font-size: 38px;
          text-align: center;
          margin-bottom: 20px;
        }

        p {
          line-height: 32px;
          font-size: 18px;
          text-align: center;

          &:not(:first-of-type) {
            margin-top: 20px;
          }
        }
      }
    }

    .wrapper-service {
      padding-top: 80px;

      h3 {
        font-size: 38px;
        text-align: center;
      }

      .service-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .service {
          margin-top: 50px;
          width: 32%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          transition: all 250ms;

          &:hover {
            transform: translateY(-5px);
          }

          &:hover p {
            color: @color2;
          }

          img {
            width: 30px;
            margin-bottom: 10px;
          }

          p {
            text-align: center;
            font-weight: 700;
            line-height: 26px;
          }
        }
      }
    }
  }

  .gallery-section-content {
    .section-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 80px;

      .gallery-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;

        .flexbox-service {
          position: relative;
          overflow: hidden;
          margin: 7px;
          width: 32%;
          padding-top: 32%;
          border-radius: 20px;

          &:hover img {
            transform: scale(1.03);
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 250ms ease;
            transform: translateZ(0);
            object-fit: cover;
          }
        }

        .service-hidden {
          width: 32%;
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }

  .cta-section-content {
    padding-top: 100px;

    .section-wrapper {
      .text {
        display: flex;
        width: 80%;
        padding: 20px 0;

        &:first-of-type {
          justify-content: flex-start;

          h2 {
            font-size: 44px;
            font-weight: 700;
            line-height: 60px;
            color: @color2;
          }
        }

        &:nth-of-type(2) {
          justify-content: flex-end;
          align-items: center;

          h3 {
            font-size: 32px;
            font-weight: 700;
            color: @color2;
          }

          a {
            font-size: 30px;
            color: @color2;
            margin-left: 10px;
            transition: color 350ms;

            &:hover {
              color: @color1;
            }
          }
        }
      }
    }
  }
}

section.cennik-section-content {
  padding: 70px 0 100px 0;

  .heading {
    .img-absolute {
      left: 50%;
      top: 80%;
      transform: translate(-50%, 50%);
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cennik-nav {
      padding-top: 50px;
      width: 100%;
      display: flex;
      justify-content: space-around;

      .nav-item {
        text-align: center;

        .nav-pill {
          font-size: 14px;
          letter-spacing: 1px;
          padding: 15px 20px;
          text-transform: uppercase;
          color: #212529;
          font-weight: 700;

          &.active {
            background-color: rgba(@color2, 0.85);
            border-radius: 30px;
            padding: 15px 20px;
            color: #fff;
            transition: background-color 250ms;

            &:hover {
              background-color: @color2;
            }
          }
        }
      }
    }

    .tab-content {
      padding-top: 70px;
      width: 100%;
      text-align: center;

      .tab-wrapper {
        .text {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          h3 {
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 20px;
          }

          .btn {
            margin-top: 50px;
          }

          .table {
            display: flex;
            flex-direction: column;
            width: 80%;

            .table-heading {
              display: flex;
              justify-content: space-between;
              font-weight: 700;
              font-size: 18px;
              padding: 10px 0;
            }

            .service {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 10px 0;
              border-bottom: 1px solid @color1;

              p:first-of-type {
                text-align: left;
              }

              p:nth-of-type(2) {
                text-align: right;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

section.kontakt-section-content {
  padding: 70px 0;

  .section-wrapper {
    display: flex;
    align-items: center;

    .section-text {
      width: 45%;
      display: flex;
      flex-direction: column;

      .address {
        margin-bottom: 20px;

        h2 {
          margin-bottom: 10px;
          font-weight: 700;
        }

        p {
          font-weight: 500;
          margin-bottom: 10px;
        }

        a {
          color: @color2;
          font-weight: 700;
          font-size: 15px;
        }
      }

      .work-hours {
        h3 {
          margin-bottom: 10px;
          font-size: 21px;
          font-weight: 700;
        }

        ul {
          li {
            border-bottom: 1px solid @color1;
            padding: 10px 0;
            width: 51%;
          }
        }
      }
    }

    .section-map {
      width: 55%;
      margin-left: 50px;

      iframe {
        width: 100%;
        height: 330px;
        border-radius: 10px;
      }
    }
  }
}

section.section-footer {
  padding-top: 80px;
  background-color: #f8f8f8;
  border-radius: 100px;
  z-index: 100;

  .footer-flexbox {
    display: flex;
    justify-content: space-between;
    position: relative;

    .img-section-absolute {
      position: absolute;
      top: -70%;
      z-index: 222;
      right: -10%;
      opacity: 0.4;
      width: 13%;
    }

    .flexbox-service {
      .service-logo {
        width: 120px;
      }

      h2 {
        margin: 10px 0;
        font-weight: 700;
        font-size: 28px;
      }

      h3 {
        margin: 10px 0;
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 1px;
      }

      p {
        font-size: 15px;
      }

      &:first-of-type {
        p {
          padding: 5px 0;
        }

        a {
          margin-top: 5px;
          font-weight: 700;
          color: @color2;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }

      &:nth-of-type(3) {
        ul {
          li {
            padding: 8px 0 10px 0;
            border-bottom: 1px solid @color1;
          }
        }
      }

      .footer-heading {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1.5px;
        color: @color2;
        margin-bottom: 25px;
      }

      ul {
        li {
          padding: 5px 0;

          a {
            font-size: 15px;
            color: #212529;
            letter-spacing: 1px;
            transition: color 250ms;

            &:hover {
              color: @color1;
            }
          }
        }
      }
    }
  }

  .footer-credits-wrapper {
    margin-top: 50px;
    background-color: @color1;
  }

  .footer-credits {
    border-top: none;
    margin: 0;
    padding: 35px 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    p {
      font-size: 14px;
      color: #fff;
      font-weight: 300;
      width: 100%;
      text-align: right;

      a {
        color: #fff;
        cursor: pointer;
        transition: color 300ms;
        font-weight: 700;

        &:hover {
          color: #212529;
        }
      }
    }
  }

  .alert {
    position: relative;
    margin: 0;
    padding: 40px 0 0 0;
    border-radius: 0;
    background: none;
    border: none;

    p {
      font-size: 15px;
      color: #fff;
      font-weight: 300;
      text-align: center;
    }

    .alert-links {
      margin-top: 10px;
      text-align: center;

      a {
        color: #fff;
        cursor: pointer;
        transition: all 0.3s;
        font-weight: 700;
        margin-right: 10px;
        font-size: 14px;

        &:hover {
          color: #212529;
        }
      }
    }
  }
}
